<template>
  <div>
    <el-form :model="form"
             :rules="rules"
             ref="formRef"
             label-width=100px>
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item label="试卷名称">
            <el-input clearable
                      placeholder="请输入"
                      style="width:100%"
                      v-model="form.paper_name" />
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="指定年级"
                        prop="grade_id">
            <el-select v-model="form.grade_id"
                       style="width:100%"
                       @change="gradeChange"
                       placeholder="选择">
              <el-option v-for="item in gradeList"
                         :key="item.grade_id"
                         :label="item.grade_name"
                         :value="item.grade_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="试卷科目"
                        prop="subject_id">
            <el-select v-model="form.subject_id"
                       @change="subjectChange"
                       style="width:100%"
                       placeholder="选择">
              <el-option v-for="item in subjectList"
                         :key="item.subject_id"
                         :label="item.subject_name"
                         :value="item.subject_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="题目地区"
                        prop="paper_district_id">
            <el-select v-model="form.paper_district_id"
                       style="width:100%"
                       placeholder="选择">
              <el-option v-for="item in testingList"
                         :key="item.paper_district_id"
                         :label="item.paper_district_desc"
                         :value="item.paper_district_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="试卷学期">
            <el-select v-model="form.semester"
                       style="width:100%"
                       placeholder="选择">
              <el-option v-for="item in semesterList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="试卷类型">
            <el-select v-model="form.edu_paper_type"
                       style="width:100%"
                       placeholder="选择">
              <el-option v-for="item in paperTypeList"
                         :key="item.paper_type_id"
                         :label="item.paper_type_name"
                         :value="item.paper_type_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="试卷时长">
            <el-input v-model="form.paper_duration"
                      maxlength="4"
                      placeholder="试卷时长(分钟)"
                      style="width:100%" />
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="题目难度"
                        prop="question_difficulty">
            <el-select v-model="form.question_difficulty"
                       style="width:100%"
                       multiple
                       placeholder="选择">
              <el-option v-for="(item,index) in difficultyList"
                         :key="index"
                         :label="item.text"
                         :value="item.val">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <!-- <el-col :span="8">
          <el-form-item label="使用类型"
                        prop="is_trial">
            <el-radio-group v-model="form.is_trial">
              <el-radio :label="0">普通试卷</el-radio>
              <el-radio :label="1">测试试卷</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col> -->
        <el-col :span="8">
          <el-form-item label="开始时间">
            <el-date-picker v-model="form.paper_time"
                            style="width:100%"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="试卷开始时间" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="截止时间"
                        prop="end_time">
            <el-date-picker v-model="form.end_time"
                            style="width:100%"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            default-time="23:59:59"
                            placeholder="试卷截止时间" />
          </el-form-item>
        </el-col>

      </el-row>
      <div class="warp">
        <!-- 说明：
        <div>
          1.全国甲卷地区包含四川、广西、贵州、云南、西藏，全国乙卷地区包含河南、江西、安
          徽、山西、陕西、黑龙江、吉林、内蒙古、甘肃、宁夏、新疆、青海，新高考I卷地区包含
          广东、福建、河北、湖南、湖北、江苏、山东，新高考II卷地区包含海南、重庆、辽宁；
        </div>
        <div>
          2.试卷可以定时开始训练，也可以不选时间，这样学生任何时间都可以训练；
        </div>
        <div>
          3.试卷可见性：哪些人可以看到这套试卷和训练这套试卷；
        </div>
        <div>
          4.组的试卷必须提交才能导出或提供给学生做题，提交后题目不能再更换。未提交的试卷不能导出或提供给学生做题。
        </div> -->
        <p style="width:100%">
          <span style="color:#f00;padding:0 4px 0 ">*</span>说明：
        </p>
        <ol>
          <li>
            试卷可以定时开始训练，也可以不选时间，这样学生任何时间都可以训练；
          </li>
          <li>
            组的试卷必须提交才能提供给学生做题。
          </li>
        </ol>
      </div>
      <el-row :gutter="10"
              style="text-align:center;margin-top:20px">
        <el-col :span="24">
          <el-form-item>
            <el-button type="primary"
                       @click="toInfo">提交</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { getByRadeClassSubject } from '@/api/getSubjectList.js'
import { checkTime } from "@/utils/date.js"

export default {
  data () {
    return {
      form: {
        paper_name: '',
        subject_id: '',
        paper_district_id: '',
        is_test_paper: '',
        paper_time: '',
        semester: 2,
        is_trial: 0,
        edu_paper_type: '',
        grade_id: '',
        paper_duration: '',
        question_difficulty: '',
        end_time: null,
      },
      showList: [],
      dialogVisible: false,
      testingList: [],
      subjectList: [],
      paperTypeList: [],
      gradeList: [],
      difficultyList: [],
      semesterList: [
        { label: '上学期', value: 0 },
        { label: '下学期', value: 1 },
        { label: '未指定', value: 2 },
      ],
      rules: {
        paper_name: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        subject_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        paper_district_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        is_test_paper: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        paper_time: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        show_to: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        grade_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        semester: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        edu_paper_type: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        paper_duration: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        end_time: [
          { required: false, message: '请选择', trigger: 'change' },
        ],
        question_difficulty: [
          { required: false, message: '请选择', trigger: 'change' },
        ],
        grade_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],

      },
    }
  },
  watch: {
    'form.paper_duration': {
      handler (newVal, oldVal) {
        var numberarr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
        if (newVal != '' && numberarr.findIndex(item => item == newVal[newVal.length - 1]) == -1) {
          this.form.paper_duration = oldVal
        }
      },
      deep: true
    }
  },
  mounted () {
    // this.getDistrictList()
    this.initData()
    this.$http({
      url: '/api/v1/public/get_record',
      method: 'get',
      params: {
        module_id: 1
      }
    }).then(res => {
      if (res.data && res.data.list.length != 0) {
        this.form = res.data.list
        if (this.form.show_to) {
          delete this.form.show_to
        }
        delete this.form.sign
        delete this.form.class_id
      }
      if (this.form.end_time && !checkTime(this.form.end_time, 1)) {
        this.form.end_time = ''
      }
    })
  },
  methods: {
    initData () {
      this.getPaperTypeList()
      this.getGradeList()
      this.getDiffcultyList()
    },
    gradeChange () {
      if (this.form.grade_id == '') return
      let form = {
        grade_id: this.form.grade_id
      }
      this.getSubjectList(form)
    },
    subjectChange () {
      this.getDistrictList()
    },
    getDiffcultyList () {
      this.$http({
        url: '/api/v1/combination/question_difficulty',
        method: 'get',
      }).then(res => {
        this.difficultyList = res.data
      })
    },
    toInfo () {
      this.$refs.formRef.validate(val => {
        this.form.is_trial = '0'
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))
        // 传递给父组件  父组件去拿学生那边的信息
        this.$emit('validate', form)
      })
    },
    async getSubjectList (data) {
      const { data: res } = await getByRadeClassSubject(data)
      this.subjectList = Object.values(res)
      if (!this.form.subject_id || this.subjectList.filter(item => item.subject_id == this.form.subject_id).length == 0) {
        this.form.subject_id = this.subjectList[0].subject_id
      }
      this.subjectChange()
    },
    getDistrictList () {
      this.$http({
        url: '/api/v1/combination/region',
        method: 'get',
        params: {
          subject_id: this.form.subject_id,
          grade_id: this.form.grade_id
        }
      }).then(res => {
        this.showList = res.data.showToList
        this.testingList = res.data.districtList
        if (!this.form.paper_district_id || this.testingList.filter(item => item.paper_district_id == this.form.paper_district_id).length == 0) {
          this.form.paper_district_id = this.testingList[0].paper_district_id
        }
        // this.getPaperTypeList()
        // this.getGradeList()
        // this.getDiffcultyList()
      })
    },
    getGradeList () {
      this.$http({
        url: '/api/v1/school/select_grade',
        method: 'get'
      }).then(res => {
        this.gradeList = res.data.list

        this.form.grade_id = this.form.grade_id || this.gradeList[0].grade_id
        this.getSubjectList({
          grade_id: this.form.grade_id
        })
      })
    },
    getPaperTypeList () {
      this.$http({
        url: '/api/v1/public/paper_type',
        method: 'GET'
      }).then(res => {
        this.paperTypeList = Object.values(res.data)
      })
    },
    handleClose () {
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.warp {
  width: 98%;
  margin: 0 auto;
  border-top: 1px solid #ddd;
  font-size: 14px;
  line-height: 24px;
  padding-top: 10px;
  ol {
    margin-left: 10px;
    list-style: auto;
  }
  div {
    margin-left: 15px;
  }
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
</style>